<template>
  <div class="page-container">
    <el-form ref="editForm" :model="form" :inline="false" label-position="right" label-width="150px" class="editForm">
      <el-form-item prop="StallRefund" label="销售退货单：" v-if="$store.state.routes.systemSetting.WmsEnabled">
        <el-switch v-model="form.AutoRefund" active-text="退货自动入库" />
      </el-form-item>
      <el-form-item prop="MultiStore" label="档口：" v-if="false">
        <el-switch v-model="form.MultiStore" active-text="启用多档口" />
      </el-form-item>
      <el-form-item prop="MultiWarehouse" label="仓库：" v-if="$store.state.routes.systemSetting.WmsEnabled">
        <el-switch v-model="form.MultiWarehouse" active-text="启用多仓库" @change="handleChanged" />
      </el-form-item>
      <el-form-item prop="PreorderPostfix" label="报单后缀：">
        <el-input type="textarea" :rows="2" v-model="form.PreorderPostfix" :style="{ width: '350px' }"></el-input>
      </el-form-item>
      <el-form-item prop="AutoSplitOrder" label="拆单：" v-if="false">
        <el-switch v-model="form.AutoSplitOrder" :disabled="!form.MultiWarehouse" active-text="自动拆单到有库存的仓库" />
      </el-form-item>
      <el-form-item prop="PaymentQRCode" label="收款二维码：">
        <div style="display:inline-block;">
          <el-upload ref="uploadImage" :multiple="true" class="avatar-uploader" accept="image/*" :action="uploadUrl"
            :headers="headers" :show-file-list="true" :file-list="form.PaymentQRCode" list-type="picture-card"
            :on-success="uploadImgSuccess" :on-error="uploadImgError" :on-remove="handleRemove">
            <i class="el-icon-plus "></i>
          </el-upload>
        </div>
        <div class="div_qr_code_attr" v-if="form.PaymentQRCode && form.PaymentQRCode.length > 0">宽度：<el-input-number
            :controls="false" v-model="form.QRCodeWidth" :max="300" :min="0" type="text"
            style="width:100px;"></el-input-number>
          px(最大300，即小票宽度；未设置为默认值)</div>
        <div class="div_qr_code_attr" v-if="form.PaymentQRCode && form.PaymentQRCode.length > 0">高度：<el-input-number
            :controls="false" v-model="form.QRCodeHeight" :min="0" type="text" style="width:100px;"></el-input-number>
          px
        </div>
      </el-form-item>
      <div class="footer">
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { submit, saveSystemSetting } from "@/api/base";
import { baseURL, successCode } from '@/config';

export default {
  name: "StallSystemSetting",
  components: {},
  data() {
    return {
      visible: true,
      uploadUrl:
        baseURL + "/api/base/file/uploadImg?path=paymentQRCode",
      headers: {
        Authorization: "Bearer " + this.$store.getters["user/accessToken"],
      },
      type: "",
      form: {},//...this.$store.state.routes.systemSetting
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    handleRemove(file, fileList) {
      this.form.PaymentQRCode = fileList;
    },
    uploadImgSuccess(response, file, fileList) {
      if (typeof (this.form.PaymentQRCode) == 'undefined') {
        this.form.PaymentQRCode = [];
      }
      if (successCode.includes(response.code)) {
        file.url = response.data;
        this.form.PaymentQRCode.push(file);
      } else {
        this.$message({
          type: 'error',
          message: response.msg
        });
      }
    },
    uploadImgError() {
      this.$message({
        type: 'error',
        message: '上传失败'
      });
    },
    handleChanged() {
      const _this = this;
      if (!_this.form.MultiWarehouse) {
        _this.form.AutoSplitOrder = false;
      }
    },
    async fetchData() {
      var rsp = await submit("/api/stall/getSystemSetting");
      this.form = rsp.data;
    },
    async save() {
      const _this = this;
      var res = await saveSystemSetting({ Json: JSON.stringify(_this.form) });
      _this.$store.state.routes.systemSetting = res.data;
      _this.$baseMessage("保存成功", "success");
      _this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  margin: 0px auto;
  padding: 10px 10px 0px 10px;
}

.main-content>div {
  height: auto !important;
}

.editForm {
  border: 1px solid #ebeef5;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-right: 50px;
}

.footer {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  .el-button {
    width: 120px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.div_qr_code_attr {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;

  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
